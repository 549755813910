import posthog from 'posthog-js'
import { Capacitor } from '@capacitor/core'

export default defineNuxtPlugin(() => {
	const { $config } = useNuxtApp()

	const posthogInstance = $config.public.enablePosthog
		? posthog.init('phc_yfvwb0g6DTjFnXIZWaoLlMRWgyw6JYA2uM8t2pKgvtQ', {
				api_host: 'https://us.i.posthog.com',
				person_profiles: 'identified_only',
		  })
		: null

	const captureEvent = (event: string, metadata?: any) => {
		if (posthogInstance) {
			posthogInstance.capture(event, metadata)
		}
	}

	const identifyUser = (user: IUser) => {
		if (posthogInstance) {
			/**
			 * @todo Add app version
			 */
			posthogInstance.identify(user._id, {
				email: user.email,
				name: `${user.personalInfo.firstName} ${user.personalInfo.lastName}`,
				hasSubscription: user.hasSubscription,
				subscription: user.plan && user.plan.name['en-US'],
				subscriptionCycle: user.subscription.interval,
				subscriptionStartDate: user.subscription.createdAt,
				isApplicationOwner: user.isApplicationOwner,
				platform: Capacitor.getPlatform(),
				createdAt: user.createdAt,
			})
		}
	}

	if (posthogInstance) {
		const router = useRouter()

		router.afterEach(to => {
			posthogInstance.capture('$pageview', {
				$current_url: to.fullPath,
				$referrer: document.referrer,
			})
		})
	}

	return {
		provide: {
			captureEvent,
			identifyUser,
			posthog,
		},
	}
})
