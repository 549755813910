<template>
	<section class="fixed top-0 left-0 z-[200] w-screen h-screen">
		<div :class="containerClass"></div>
		<video
			v-if="!isNativePlatform"
			ref="videoRef"
			class="h-full w-full fixed top-0 left-0 object-cover object-center"
			playsinline
		></video>
		<div v-if="isNativePlatform" id="cameraPreview"></div>
		<canvas ref="canvasRef" class="hidden"></canvas>
		<div class="fixed top-4 right-4">
			<Button @click="onCloseScanner" class="h-12 w-12 rounded-full" variant="tertiary">
				<span class="fi fi-br-cross flex text-xs"></span>
			</Button>
		</div>
		<div class="fixed bottom-0 px-4 pb-6 z-[200] w-full">
			<div class="bg-brand-neutral-1/95 px-4 py-6 rounded-xl space-y-4" style="backdrop-filter: blur(30px)">
				<h2 class="text-brand-neutral-6 font-bold text-xl">Abrir carteira do cliente</h2>
				<p class="text-brand-neutral-4 text-sm">
					Aponte a camera ao QR code do cliente para enviar pontos ou resgatar recompensas
				</p>
			</div>
		</div>
	</section>
</template>
<script setup lang="ts">
const CAMERA_TICK_TIMEOUT = 1000
const CAMERA_HEIGHT = 256
const CAMERA_WIDTH = 256

import jsQR from 'jsqr'
import { Capacitor } from '@capacitor/core'
import { CameraPreview } from '@capacitor-community/camera-preview'
import { twMerge } from 'tailwind-merge'

const videoRef = ref<HTMLVideoElement | null>(null)
const canvasRef = ref<HTMLCanvasElement | null>(null)
const cameraStream = ref<MediaStream | null>(null)
const isScannerOpened = ref(true)
const hasAllowedAppCameraPermission = ref(false)
const isNativePlatform = ref(Capacitor.isNativePlatform())
const cameraInterval = ref<NodeJS.Timeout | null>(null)

const { addAlert } = useAlertsStore()
const { closeScanner } = useQRCodeScanner()
const { $config, $utils } = useNuxtApp()

const verifyImageData = (imageData: ArrayBufferLike, width: number, height: number) => {
	const code = jsQR(new Uint8ClampedArray(imageData), width, height)

	if (code) {
		const { data } = code

		if (!data.includes($config.public.baseUrl as string)) {
			addAlert({
				title: 'QRCode inválido',
				variant: 'danger',
			})
		} else {
			const route = data.replace($config.public.baseUrl as string, '')

			if (route) {
				isScannerOpened.value = false
				onCloseScanner()
				navigateTo(route)
			}
		}
	}
}

const processWebVideoFrame = () => {
	if (!isScannerOpened.value) return

	if (videoRef.value && canvasRef.value && videoRef.value.readyState === videoRef.value.HAVE_ENOUGH_DATA) {
		const ctx = canvasRef.value?.getContext('2d', {
			willReadFrequently: true,
		})

		canvasRef.value.width = videoRef.value.videoWidth
		canvasRef.value.height = videoRef.value.videoHeight

		ctx?.drawImage(videoRef.value, 0, 0, canvasRef.value.width, canvasRef.value.height)

		const imageData = ctx?.getImageData(0, 0, canvasRef.value.width, canvasRef.value.height)

		if (imageData) {
			verifyImageData(imageData.data.buffer, canvasRef.value.width, canvasRef.value.height)
		}
	}

	requestAnimationFrame(processWebVideoFrame)
}

const loadImageFromBase64 = (base64: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = `data:image/jpeg;base64,${base64}`
		img.onload = () => resolve(img)
		img.onerror = err => reject(err)
	})
}

const processMobileVideoFrame = async () => {
	if (!isScannerOpened.value || !canvasRef.value) return

	const ctx = canvasRef.value.getContext('2d', {
		willReadFrequently: true,
	})
	const result = await CameraPreview.capture({ quality: 80, width: CAMERA_HEIGHT, height: CAMERA_WIDTH })
	const img = await loadImageFromBase64(result.value)

	canvasRef.value.height = CAMERA_HEIGHT
	canvasRef.value.width = CAMERA_WIDTH

	ctx?.drawImage(img, 0, 0, CAMERA_WIDTH, CAMERA_HEIGHT)

	const imageData = ctx?.getImageData(0, 0, CAMERA_WIDTH, CAMERA_HEIGHT)

	if (imageData) {
		verifyImageData(imageData.data.buffer, CAMERA_WIDTH, CAMERA_HEIGHT)
	}
}

const onCloseScanner = () => {
	closeScanner()

	if (isNativePlatform) {
		CameraPreview.stop()
	}

	if (cameraStream.value) {
		cameraStream.value.getTracks().forEach(track => {
			track.stop()
		})
	}

	if (videoRef.value) {
		videoRef.value.pause()
		videoRef.value.srcObject = null
	}

	if (cameraInterval.value) {
		clearInterval(cameraInterval.value)
	}
}

const containerClass = computed(() =>
	twMerge('h-full w-full fixed top-0 left-0', isNativePlatform ? 'bg-transparent' : 'bg-brand-neutral-7')
)

onMounted(async () => {
	if (!Capacitor.isNativePlatform()) {
		navigator.mediaDevices
			.getUserMedia({ video: { facingMode: 'environment' } })
			.then(stream => {
				if (videoRef.value) {
					cameraStream.value = stream
					videoRef.value.srcObject = stream
					videoRef.value.play()
					requestAnimationFrame(processWebVideoFrame)
				}
			})
			.catch(error => {
				console.error(error)

				addAlert({
					title: 'Erro ao acessar a câmera',
					variant: 'danger',
				})
			})
	} else {
		hasAllowedAppCameraPermission.value = await $utils.hasAllowedAppCameraPermission()

		if (!hasAllowedAppCameraPermission.value) {
			addAlert({
				title: 'É preciso permitir o acesso à câmera para usar o scanner',
				variant: 'danger',
			})

			onCloseScanner()
			return
		}

		if (isNativePlatform.value) {
			CameraPreview.start({
				position: 'rear',
				parent: 'cameraPreview',
				toBack: true,
			})

			cameraInterval.value = setInterval(processMobileVideoFrame, CAMERA_TICK_TIMEOUT)
		}
	}
})

onUnmounted(() => {
	isScannerOpened.value = false
})
</script>
