import { defineStore } from "pinia";
import type {
    Alert,
    AlertVariant,
} from "~/types/components/alert.interface";

export const useAlertsStore = defineStore("alerts", {
    state: () => ({
        alerts: [] as Alert[],
    }),
    actions: {
        addAlert({ title, variant }: { title: string; variant: AlertVariant }) {
            this.alerts.push({
                id: Date.now(),
                title,
                variant,
            });
        },
        removeAlert(id: number) {
            this.alerts = this.alerts.filter((alert) => alert.id !== id);
        },
    },
    getters: {
        getAlerts: (state) => state.alerts,
    },
});
