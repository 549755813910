<template>
	<NuxtLayout>
		<NuxtPage v-if="!isScannerOpened" />
		<Alert v-for="alert in alertsStore.getAlerts" :key="alert.id" :title="alert.title" :variant="alert.variant" />
		<QRCodeScanner v-if="isScannerOpened" />
	</NuxtLayout>
</template>
<script setup lang="ts">
const ALERT_DURATION_IN_MS = 3000
const siteConfig = useSiteConfig()
const alertsStore = useAlertsStore()
const qrCodeScanner = useQRCodeScanner()
const { alerts } = storeToRefs(alertsStore)
const { isScannerOpened } = storeToRefs(qrCodeScanner)

watch(
	alerts,
	() => {
		if (!alerts.value.length) return

		setTimeout(() => {
			if (!alerts.value.length) return
			alertsStore.removeAlert(alerts.value[0].id)
		}, ALERT_DURATION_IN_MS)
	},
	{ deep: true }
)

useSeoMeta({
	title: siteConfig.name,
	ogTitle: siteConfig.name,
	description: siteConfig.description,
	ogDescription: siteConfig.description,
	ogImage: 'https://cdn.iloyal.com.br/assets/images/og-image.png',
	twitterCard: 'summary_large_image',
	robots: siteConfig.indexable ? 'index, follow' : 'noindex, nofollow',
})
</script>
