<template>
    <button
        v-if="!href"
        v-bind="attrs"
        :class="mergedClass"
        :disabled="isLoading || isDisabled"
        :type="type"
    >
        <slot v-if="!isLoading"></slot>
        <span
            v-if="isLoading"
            class="fi fi-sr-spinner animate-spin flex"
        ></span>
    </button>
    <NuxtLink v-else :to="href" :class="mergedClass" v-bind="attrs">
        <slot></slot>
    </NuxtLink>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import type { ButtonHTMLAttributes } from "vue";

defineOptions({
    inheritAttrs: false,
});

const props = defineProps<{
    variant?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "outline"
        | "transparent"
        | "danger";
    href?: string;
    isLoading?: boolean;
}>();
const attrs = useAttrs();

const variantClass = {
    primary: "bg-brand-primary-1 text-brand-neutral-1",
    secondary: "bg-brand-neutral-7 text-brand-neutral-1",
    tertiary: "bg-brand-neutral-3 text-brand-neutral-7",
    outline:
        "bg-transparent border-2 border-brand-neutral-3 text-brand-neutral-7",
    transparent: "bg-transparent text-brand-neutral-7",
    danger: "bg-red-500 text-brand-neutral-1",
}[props.variant || "primary"];

const type = (attrs.type as ButtonHTMLAttributes["type"]) || "button";
const isLoading = computed(() => props.isLoading);
const isDisabled = computed(() => !!attrs.disabled);
const mergedClass = computed(() =>
    twMerge(
        "w-full rounded-xl px-6 py-3.5 text-base flex text-center items-center justify-center disabled:opacity-50 gap-4 h-[52px] font-semibold",
        variantClass,
        attrs.class as string
    )
);
</script>
