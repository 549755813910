<template>
    <div role="alert" class="fixed top-8 w-full md:w-96 px-4 z-[500]">
        <div :class="mergedClass">
            <div class="mr-12">{{ title }}</div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { twMerge } from 'tailwind-merge';

const props = defineProps<{
    variant?: AlertVariant;
    title: string;
}>();

type VariantClassObj = {
    [key in AlertVariant]: string;
};

const variantClassObj = {
    success: "bg-green-600",
    danger: "bg-red-500",
    warning: 'bg-yellow-500'
} as VariantClassObj;

const variantClass = variantClassObj[props.variant || "success"];
const mergedClass = twMerge(
    "block w-full px-4 py-4 text-base text-white bg-gray-900 rounded-lg font-regular",
    variantClass,
);
</script>
