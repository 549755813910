import { defineStore } from "pinia";

export const useQRCodeScanner = defineStore("scanner", {
    state: () => ({
        isScannerOpened: false,
    }),
    actions: {
        openScanner() {
            this.isScannerOpened = true;
        },
        closeScanner() {
            this.isScannerOpened = false;
        },
    },
    getters: {
        getScannerStatus: (state) => state.isScannerOpened,
    },
});
